import Building from '@/components/structures/building'

class Advert {
  constructor () {
    this.id = null
    this.source = null
    this.owner_status_local = null
    this.adv_status = 2
    this.adv_type = 4
    this.flat_type = null
    this.flat_number = null
    this.room_count = null
    this.floor = null
    this.flat_status = null
    this.price = null
    this.price_history = []
    this.area_common = null
    this.area_living = null
    this.area_kitchen = null
    this.kitchen_type = null
    this.description_default = null
    this.description_public = null
    this.description_service = null
    this.is_readonly = false
    this.is_published = 0
    this.is_verified = false
    this.is_editing = false
    this.building = new Building()
    this.advertPhones = []
    this.userAdverts = []
    this.advertExtras = []
    this.advertImages = []
    this.userInCharge = null
    this.delay_until = null
    this.delay_comment = null
    this.exclusive_status = null
    this.published_at = null
    this.advertExternalIds = []
  }
}

export default Advert
